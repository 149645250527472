import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { environment } from '../environments/environment';

import { OfflineModalComponent } from './offline-modal/offline-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'CharityApp';
  constructor(private matDialog: MatDialog) {}

  ngOnInit(): void {
    const config = environment.hotjarConfig;
    if ('hotjarId' in config) {
      import('@hotjar/browser').then(({ default: Hotjar }) => {
        Hotjar.init(config.hotjarId, config.hotjarVersion);
      });
    }

    import('@datadog/browser-rum').then(({ datadogRum }) => {
      datadogRum.onReady(function () {
        datadogRum.init(environment.ddRumConfig);
        datadogRum.startSessionReplayRecording();
      });
    });

    window.addEventListener('offline', () => {
      this.matDialog.open(OfflineModalComponent, {
        panelClass: 'offline-dialog',
      });
    });
    window.addEventListener('online', () => this.matDialog.closeAll());
  }
}
