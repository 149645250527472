import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigurationService } from './configuration.service';
import type { AuthenticatedUser, AuthResponse, UserProfile } from './types';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private user: AuthenticatedUser | null = null;

  constructor(
    private http: HttpClient,
    public configurationService: ConfigurationService,
    public router: Router
  ) {
    this.user = JSON.parse(sessionStorage.getItem('auth')) || null;
  }

  signin(username, password) {
    const authUrl = this.configurationService.apiUrl + '/auth';
    const body = {
      username,
      password,
      clientSubdomain: this.configurationService.config.clientName,
    };
    return new Observable((obs) => {
      this.http
        .post(authUrl, body)
        .pipe(
          map((res: AuthResponse) => {
            sessionStorage.setItem('auth', JSON.stringify(res));
            this.user = { ...res, profile: {} };
            return res;
          })
        )
        .subscribe(
          (authData) => {
            this.getProfile().subscribe(() => {
              obs.next(authData);
              obs.complete();
            });
          },
          (err) => {
            obs.next(null);
            obs.complete();
          }
        );
    });
  }

  getProfile() {
    const profileUrl = this.configurationService.apiUrl + '/user/profile';
    return this.http.get(profileUrl).pipe(
      map((res: UserProfile) => {
        this.user.profile = res;
        sessionStorage.setItem('auth', JSON.stringify(this.user));
        return res;
      })
    );
  }

  logout() {
    this.user = null;
    sessionStorage.removeItem('auth');
    this.router.navigate(['/login']);
  }

  getUser() {
    return this.user;
  }

  userAvatarLink() {
    return (
      this.configurationService.apiUrl +
      '/users/' +
      // @ts-expect-error profile is: {} | UserProfile. TODO: handle empty profile
      this.user.profile.userId +
      '/avatar?token=' +
      this.user.token
    );
  }

  getUserAvatarLink(id) {
    return this.configurationService.apiUrl + '/users/' + id + '/avatar?token=' + this.user.token;
  }

  getUserName() {
    // @ts-expect-error profile is: {} | UserProfile. TODO: handle empty profile
    return this.user.profile.firstName + ' ' + this.user.profile.lastName;
  }
}
