import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { EvaluationPV0 } from '../../admin/core/types';

import { ConfigurationService } from './configuration.service';
import {
  Course,
  CoursePV0,
  GUID,
  ParticipantUser,
  Scenario,
  ScenarioItem,
  ScenarioItemPV0,
} from './types';

export {
  Course,
  CoursePV0,
  EvaluationPV0,
  ParticipantUser,
  Scenario,
  ScenarioItem,
  ScenarioItemPV0,
};
@Injectable({ providedIn: 'root' })
export class CoursesService {
  selectedScenario: BehaviorSubject<any> = new BehaviorSubject({});
  scenarioItems: BehaviorSubject<any> = new BehaviorSubject([]);
  selectedScenarioId: any;
  selectedCourseId: any;

  constructor(
    protected http: HttpClient,
    public configurationService: ConfigurationService,
    public router: Router
  ) {}

  getScenarios() {
    return this.http.get(this.configurationService.apiUrl + '/live/scenarios');
  }

  getScenario(scenarioId: GUID) {
    return this.http.get<Scenario>(this.configurationService.apiUrl + '/scenarios/' + scenarioId);
  }

  getParticipantsUsers(courseId: GUID) {
    return this.http.get<ParticipantUser[]>(
      this.configurationService.apiUrl + `/courses/${courseId}/participants-with-evaluations`
    );
  }

  getCourse(courseId: GUID) {
    return this.http.get<Course>(this.configurationService.apiUrl + `/courses/${courseId}`);
  }

  getCoursePV0(courseId: GUID) {
    return this.http.get<CoursePV0>(
      this.configurationService.apiUrl + `/platform-api/v0/admin/courses/${courseId}`
    );
  }

  getScenarioItems(scenarioId: GUID) {
    return this.http.get<ScenarioItem[]>(
      this.configurationService.apiUrl + `/scenarios/${scenarioId}/items`
    );
  }

  getScenarioItemsPV0(scenarioId: GUID) {
    return this.http.get<ScenarioItemPV0[]>(
      this.configurationService.apiUrl +
        `/platform-api/v0/admin/scenarios/${scenarioId}/items/titles`
    );
  }

  getEvaluationTemplate(courseId: GUID, templateId: GUID) {
    return this.http.get(
      this.configurationService.apiUrl + `/courses/${courseId}/evaluation-templates/${templateId}`
    );
  }

  getQuestionTypes() {
    return this.http.get(this.configurationService.apiUrl + `/question-types`);
  }

  getParticipantCourse() {
    return this.http.get(this.configurationService.apiUrl + `/courses/participant-courses`);
  }

  createEvaluation(templateId: GUID) {
    return this.http.post(
      this.configurationService.apiUrl + `/evaluations/evaluation-templates/${templateId}/course`,
      {}
    );
  }

  getEvaluations(evaluationId: GUID) {
    return this.http.get(
      this.configurationService.apiUrl + `/evaluations/${evaluationId}/in-progress`
    );
  }

  getEvaluationsPV0(courseId: GUID) {
    return this.http.get<EvaluationPV0[]>(
      this.configurationService.apiUrl + `/platform-api/v0/admin/courses/${courseId}/scores`
    );
  }

  submitEvaluations(evaluationId: GUID, data) {
    if (data.answers.length === 0) {
      throw new Error('Evaluation has no answers but all questions are required.');
    }
    return this.http.put(
      this.configurationService.apiUrl + `/evaluations/${evaluationId}/submit`,
      data
    );
  }

  getScore(scoreId) {
    return this.http.get(
      this.configurationService.apiUrl +
        `/evaluations/participant-course-evaluations-with-scores/${scoreId}`
    );
  }

  getResults(evaluationId: GUID) {
    return this.http.get(
      this.configurationService.apiUrl + `/evaluations/${evaluationId}/completed`
    );
  }
}
