import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent {
  questionVal: any = {};
  @Input() get question() {
    return this.questionVal;
  }
  set question(val) {
    this.questionVal = val;
    this.questionChange.emit(this.questionVal);
  }
  @Output() questionChange = new EventEmitter();

  constructor() {}
}
