import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { RumInitConfiguration } from '@datadog/browser-rum';

import { environment } from '../../../environments/environment';
interface ConfigData {
  configUrl: string;
  clientName: string;
  apiUrl?: string;
  ddRumConfig: RumInitConfiguration;
}

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private configData: ConfigData = {
    configUrl: environment.configUrl,
    clientName: environment.clientName,
    ddRumConfig: environment.ddRumConfig,
  };

  constructor(private http: HttpClient) {}

  async loadConfigurationData(): Promise<void> {
    if (environment.apiUrl) {
      this.configData.apiUrl = environment.apiUrl;
    } else {
      try {
        this.configData.apiUrl = await this.http
          .get(environment.configUrl, { responseType: 'text' })
          .toPromise();
      } catch (error) {
        this.configData.apiUrl = 'https://api.simcapture.com';
      }
    }
  }

  get config() {
    return this.configData;
  }

  get apiUrl() {
    return this.configData.apiUrl;
  }
}
