import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AssessmentsComponent } from './assessments/assessments.component';
import { AdminGuard } from './core/guards/admin.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { UserGuard } from './core/guards/user.guard';
import { LayoutComponent } from './layout/layout.component';
import { ResultsComponent } from './results/results.component';
import { ScoreComponent } from './score/score.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard, UserGuard],
    children: [
      {
        path: 'landing',
        loadChildren: () => import('./landing/landing.module').then((m) => m.LandingModule),
      },
      { path: 'assessments/:evaluationId', component: AssessmentsComponent },
      {
        path: 'score/:courseId/:evaluationTemplateId',
        component: ScoreComponent,
      },
      { path: 'results/:evaluationId', component: ResultsComponent },
      { path: 'results/:evaluationId/:type', component: ResultsComponent },
    ],
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () => import('./admin/app.module').then((m) => m.AppModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
