import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AuthenticationService } from '../core/services/authentication.service';
import { ConfigurationService } from '../core/services/configuration.service';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss'],
})
export class ControlsComponent implements OnInit {
  questionVal: any = {};
  @Input() get question() {
    return this.questionVal;
  }
  set question(val) {
    this.questionVal = val;
    this.questionChange.emit(this.questionVal);
  }
  @Output() questionChange = new EventEmitter();

  @Output() showHint = new EventEmitter();

  constructor(
    public configurationService: ConfigurationService,
    public authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    if (this.question.type.ref === 'instruction') {
      this.question.required = false;
    }
  }

  questionHint(q) {
    if (q.rawHintText) {
      this.showHint.next(q.rawHintText);
    }
  }

  getStyles(inlineStyleRanges: any) {
    const styles: any = {};
    inlineStyleRanges.forEach((style: any) => {
      if (style.style === 'ITALIC') {
        styles['font-style'] = 'italic';
      }
      if (style.style === 'UNDERLINE') {
        styles['text-decoration'] = 'underline';
      }
      if (style.style.startsWith('fontsize')) {
        styles['font-size'] = style.style.split('-')[1] + 'px';
      }
      if (style.style.startsWith('color')) {
        styles.color = style.style.split('-')[1];
      }
    });
    return styles;
  }

  getAttachmentUrl() {
    return (
      this.configurationService.apiUrl +
      `/scenarios/${this.question.scenarioId}/evaluation-templates/${
        this.question.evaluationTemplateId
      }/attachments/${this.question.assets[0].uri}?token=${
        this.authenticationService.getUser().token
      }`
    );
  }
}
