import type { RumInitConfiguration } from '@datadog/browser-rum';

import packageJson from '../../package.json';
export type RumHostType = 'local' | 'dev' | 'prod';

const DEV_CLIENT_NAME = 'lift-dev';
const DEV_UI_DOMAIN = 'hmbs-classroom-dev.com';
const PROD_CLIENT_NAME = 'lgh-ent';
const PROD_UI_DOMAIN = 'hmbs-classroom.com';
const DEV_API_URL = 'https://api.dev.simcapture-dev.com';
const PROD_API_URL = 'https://api.simcapture.com';

export function getClientNameFromHostname(hostname) {
  if (hostname.endsWith(PROD_UI_DOMAIN)) {
    return hostname.split('.')[0];
  }
  if (hostname.endsWith(DEV_UI_DOMAIN)) {
    if (hostname.startsWith('branch-')) {
      return DEV_CLIENT_NAME;
    }
    return hostname.split('.')[0];
  }
  return DEV_CLIENT_NAME;
}

export function getApiUrl({ hostname }: HostEnvParam = window.location) {
  switch (getRumHostType({ hostname })) {
    case 'prod':
      return PROD_API_URL;
    default:
      return DEV_API_URL;
  }
}

export function getConfigUrl({ hostname }: HostEnvParam = window.location) {
  const [client, domain = '-dev'] = hostname.split('.');
  const configHost = domain.endsWith('-dev') ? 'simcapture-dev.com' : 'simcapture.com';
  const configUrl = `https://${client}.${configHost}/api.host`;

  return configUrl;
}

type HostEnvParam = {
  hostname?: string;
};

function getRumHostType({ hostname }: HostEnvParam = window.location): RumHostType {
  switch (hostname) {
    case `${DEV_CLIENT_NAME}.${DEV_UI_DOMAIN}`:
      return 'dev';
    case `${PROD_CLIENT_NAME}.${PROD_UI_DOMAIN}`:
      return 'prod';
    default:
      return 'local';
  }
}

// applicationId & clientToken depend on what host/server is running the app; not which --configuration is specified
const datadogConfig = {
  dev: {
    applicationId: 'd86bc4d7-7f80-4c79-bbf8-dc1a44a65d39',
    clientToken: 'pubde14674dfaa35bb02c8f6b4ac1510f5a',
  },
  // same as dev
  local: {
    applicationId: 'd86bc4d7-7f80-4c79-bbf8-dc1a44a65d39',
    clientToken: 'pubde14674dfaa35bb02c8f6b4ac1510f5a',
  },
  prod: {
    applicationId: '58a7ab0d-5c3e-4179-be42-5557d888e77b',
    clientToken: 'pub378f9446cf3ae26ee9ac836acda20869',
  },
} as const;

const hotjarConfig = {
  dev: { hotjarId: 2158332 },
  prod: { hotjarId: 2396544 },
  // do not use locally
  local: {},
} as const;

const byVendorByHostType = {
  datadog: datadogConfig,
  hotjar: hotjarConfig,
} as const;

export function getDatadogConfig(): RumInitConfiguration {
  const hostType = getRumHostType(window.location);
  const credentials = byVendorByHostType.datadog[hostType];
  const apiOrigins =
    hostType === 'prod'
      ? [/https:\/\/.*\.simcapture\.com/, /https:\/\/.*\..*\.simcapture\.com/]
      : [
          /https:\/\/.*\.simcapture-dev\.com/,
          /https:\/\/.*\..*\.simcapture-dev\.com/,
          /http:\/\/.*\..*\..*\.simcapture-dev\.com/,
        ];

  const rumConfig: RumInitConfiguration = {
    ...credentials,
    env: hostType,
    site: 'datadoghq.com',
    service: 'lift',
    version: ENV.APP_VERSION || packageJson.version || 'missing',
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
    beforeSend: (event, context) => {
      var connection: NetworkInformation | LegacyAndroidNetworkInformation | undefined =
        // @ts-expect-error `navigator.connection` isn't in TS's definitions
        navigator.connection || navigator.mozConnection || navigator.webkitConnection;

      if (connection) {
        // tried the `event.context = {...event.context, connection}` approach
        // shown in https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#enrich-rum-events
        // and some others (Object.assign, etc) but for some reason `connection` cannot be directly applied to context
        // so pull the individual properties off
        // @ts-expect-error don't worry about undefined effectiveType, downlink, etc
        const { type, effectiveType, downlink, downlinkMax, rtt } = connection;

        // `event.context.connection = { type, etc} ` worked locally, but sticking with the syntax shown in
        //  https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#enrich-rum-events
        event.context = {
          ...event.context,
          connection: {
            type,
            effectiveType,
            downlink,
            downlinkMax,
            rtt,
          },
        };
      }
    },
    allowedTracingOrigins: [
      // typical local meteor-api on 8080
      window.location.protocol + '//' + window.location.hostname + ':8080',
      // the current origin
      window.location.origin,
      // simcapture origins
      ...apiOrigins,
    ],
  };

  return rumConfig;
}

export function getHotjarConfig() {
  const hostType = getRumHostType(window.location);
  const config = byVendorByHostType.hotjar[hostType];

  return {
    ...config,
    hotjarVersion: 6,
  };
}

// https://www.davidbcalhoun.com/2010/optimizing-based-on-connection-speed-using-navigator.connection-on-android-2.2-/
interface LegacyAndroidNetworkInformation {
  type: '3';
  UNKNOWN: '0';
  ETHERNET: '1';
  WIFI: '2';
  CELL_2G: '3';
  CELL_3G: '4';
}

interface NetworkChangeEvent extends Event {
  type: 'change';
  currentTarget: NetworkInformation;
  srcElement: NetworkInformation;
  target: NetworkInformation;
}

// https://wicg.github.io/netinfo/#networkinformation-interface
interface NetworkInformation extends EventTarget {
  type: ConnectionType;
  effectiveType: EffectiveConnectionType;
  downlinkMax: Megabit;
  downlink: Megabit;
  rtt: Millisecond;
  onchange: (event: NetworkChangeEvent) => void | unknown;
}

type Megabit = string;
type Millisecond = string;
type ConnectionType =
  | 'bluetooth'
  | 'cellular'
  | 'ethernet'
  | 'mixed'
  | 'none'
  | 'other'
  | 'unknown'
  | 'wifi'
  | 'wimax';

type EffectiveConnectionType = '2g' | '3g' | '4g' | 'slow-2g';
