import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { ConfigurationService } from '../services/configuration.service';

@Injectable({ providedIn: 'root' })
export class HeadersInterceptor implements HttpInterceptor {
  constructor(
    public configurationService: ConfigurationService,
    public authService: AuthenticationService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    let clonedRequest = req;
    const user = this.authService.getUser();
    if (req.url.startsWith(this.configurationService.apiUrl) && (user || {}).token) {
      clonedRequest = clonedRequest.clone({
        setHeaders: {
          token: user.token,
          'client-id': user.clientId,
        },
      });
    }
    if (clonedRequest.headers.get('notset-content') !== 'true') {
      clonedRequest = clonedRequest.clone({
        headers: clonedRequest.headers.set('Content-Type', 'application/json'),
      });
    }

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest);
  }
}
