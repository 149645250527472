<app-page-loading-spinner *ngIf="loading"></app-page-loading-spinner>

<div class="header">
  <div class="sub-title">Assessment</div>
  <div class="title">{{ template?.privateTitle }}</div>
</div>

<div class="info-container">
  <app-controls
    class="with-border"
    [question]="question"
    (showHint)="hintText = $event"
    *ngFor="let question of questions"
  ></app-controls>

  <div class="finish-preview" *ngIf="!questions.length && !loading">
    <div class="span">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <circle cx="16" cy="16" r="12" fill="#B0D7E7" />
        <path
          d="M13.5467 19.4751L10.6551 16.5834C10.3301 16.2584 9.80508 16.2584 9.48008 16.5834C9.15508 16.9084 9.15508 17.4334 9.48008 17.7584L12.9634 21.2418C13.2884 21.5668 13.8134 21.5668 14.1384 21.2418L22.9551 12.4251C23.2801 12.1001 23.2801 11.5751 22.9551 11.2501C22.6301 10.9251 22.1051 10.9251 21.7801 11.2501L13.5467 19.4751Z"
          fill="white"
        />
      </svg>
    </div>
    <div class="finish-text">
      Press FINISH <br />
      to see assessments
    </div>
  </div>
</div>

<div class="popup-wrap" [class.opened]="hintText">
  <div class="hide-arrow" (click)="hintText = null">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.29201 0.802567C0.682046 0.411555 1.31521 0.410763 1.70622 0.800799L8 7.07886L14.2938 0.800799C14.6848 0.410763 15.318 0.411555 15.708 0.802567C16.098 1.19358 16.0972 1.82674 15.7062 2.21678L8.70622 9.1993C8.3159 9.58865 7.6841 9.58865 7.29378 9.1993L0.293778 2.21678C-0.0972345 1.82674 -0.0980259 1.19358 0.29201 0.802567Z"
        fill="#212121"
      />
    </svg>
  </div>
  <div class="popup-header">
    <div class="title">HINTS</div>
  </div>
  <div class="paragraph-wrap">
    <div class="paragraph-title">{{hintText}}</div>
  </div>
</div>

<div class="finish-popup" [class.opened]="showFinish">
  <div class="popup-content">
    <div class="sub-title">Please confirm</div>
    <div class="title">Are you sure you want to finish {{ template?.privateTitle }}?</div>
    <div class="actions">
      <button class="primary-btn" (click)="showFinish = false">Cancel</button>
      <button
        class="primary-btn"
        [class.grey]="submitting"
        [disabled]="submitting"
        (click)="submit()"
      >
        Finish<mat-spinner *ngIf="submitting" color="primary" diameter="35"></mat-spinner>
      </button>
    </div>
  </div>
</div>
<div class="actions-bottom">
  <div *ngIf="requiredQuestionsWithAnswerOptions.length" class="questions-count">
    {{countDone()}}/{{requiredQuestionsWithAnswerOptions.length}}
  </div>
  <button class="primary-btn" [disabled]="!isValid()" (click)="showFinish = true">Finish</button>
</div>
