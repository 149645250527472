import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { CoursesService } from '../core/services/courses.service';

@Component({
  selector: 'app-assessments',
  templateUrl: './assessments.component.html',
  styleUrls: ['./assessments.component.scss'],
})
export class AssessmentsComponent implements OnInit {
  questions: any = [];
  requiredQuestionsWithAnswerOptions: any = [];
  hintText: any;
  showFinish: any;
  private courseId: any;
  private evaluationId: any;
  template: any;
  loading = false;
  submitting = false;
  // private course: any;

  constructor(
    public coursesService: CoursesService,
    public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.loading = true;
    // this.courseId = this.route.snapshot.params['id'];
    this.evaluationId = this.route.snapshot.params.evaluationId;
    // this.coursesService.getCourse(this.courseId).subscribe((res: any) => {
    //   this.course = res;
    // });

    forkJoin([
      this.coursesService.getQuestionTypes(),
      this.coursesService.getEvaluations(this.evaluationId),
    ]).subscribe((res: any) => {
      const questionTypes = res[0];
      this.template = res[1].evaluationTemplate;
      this.questions = res[1].evaluationTemplate.questions.sort(
        (a, b) => a.sortOrder - b.sortOrder
      );
      this.questions.forEach((q: any) => {
        q.type = questionTypes.find((qt) => qt.questionTypeId === q.questionTypeId);
        q.answerOptions = q.answerOptions.sort((a, b) => a.sort - b.sort);
        q.courseId = this.courseId;
      });
      this.questions = this.questions.filter((q: any) =>
        ['multiple_choice', 'text', 'instruction'].includes(q.type.ref)
      );
      this.requiredQuestionsWithAnswerOptions = this.questions.filter(
        (q: any) =>
          q.answerOptions && Array.isArray(q.answerOptions) && q.answerOptions.length && q.required
      );
      this.loading = false;
    });
  }

  isValid() {
    return this.questions.every((q) => {
      return q.required
        ? Array.isArray(q.answerValue)
          ? q.answerValue.length
          : q.answerValue
        : true;
    });
  }

  countDone() {
    return this.questions.filter(
      (q: any) =>
        ['multiple_choice', 'text', 'instruction'].includes(q.type.ref) &&
        q.required &&
        (Array.isArray(q.answerValue) ? q.answerValue.length : q.answerValue)
    ).length;
  }

  submit() {
    this.submitting = true;
    const data = [];
    this.questions.forEach((q: any) => {
      if (Array.isArray(q.answerValue)) {
        q.answerValue.forEach((aId) => {
          const answerOptionId = q.type.ref === 'text' ? q.answerOptions[0].answerOptionId : aId;
          const text = q.type.ref === 'text' ? aId : '';

          if (answerOptionId) {
            data.push({
              answerId: uuidv4(),
              answerOptionId,
              text,
            });
          }
        });
      } else if (q.type.ref !== 'instruction') {
        const answerOptionId =
          q.type.ref === 'text' ? q.answerOptions[0].answerOptionId : q.answerValue;
        const text = q.type.ref === 'text' ? q.answerValue : '';

        if (answerOptionId) {
          data.push({
            answerId: uuidv4(),
            answerOptionId,
            text,
          });
        }
      }
    });

    this.coursesService.submitEvaluations(this.evaluationId, { answers: data }).subscribe(
      () => {
        this.submitting = false;
        this.router.navigate(['/landing'], { replaceUrl: true });
      },
      () => {
        this.submitting = false;
      }
    );
  }
}
