import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AssessmentsComponent } from './assessments/assessments.component';
import { CheckboxComponent } from './controls/checkbox/checkbox.component';
import { ControlsComponent } from './controls/controls.component';
import { FreeTextComponent } from './controls/free-text/free-text.component';
import { PageLoadingSpinnerModule } from './controls/page-loading-spinner/page-loading-spinner.module';
import { RadioButtonComponent } from './controls/radio-button/radio-button.component';
import { CoreModule } from './core/core.module';
import { ConfigurationService } from './core/services/configuration.service';
import { LayoutComponent } from './layout/layout.component';
import { OfflineModalComponent } from './offline-modal/offline-modal.component';
import { ResultsComponent } from './results/results.component';
import { ScoreComponent } from './score/score.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    ControlsComponent,
    RadioButtonComponent,
    FreeTextComponent,
    CheckboxComponent,
    AssessmentsComponent,
    ScoreComponent,
    ResultsComponent,
    OfflineModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    MatDialogModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    PageLoadingSpinnerModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigurationService) => () =>
        configService.loadConfigurationData(),
      deps: [ConfigurationService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
