import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { CoursesService } from '../core/services/courses.service';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit {
  evaluationId: any;
  questions: any[] = [];
  title: string;
  survey = false;
  loading = false;

  constructor(private coursesService: CoursesService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.loading = true;
    this.evaluationId = this.route.snapshot.params.evaluationId;
    this.survey = this.route.snapshot.params.type === 'survey';

    forkJoin([
      this.coursesService.getQuestionTypes(),
      this.coursesService.getResults(this.evaluationId),
    ]).subscribe((res: any) => {
      const questionTypes = res[0];
      this.questions = res[1].evaluationTemplate.questions.sort(
        (a, b) => a.sortOrder - b.sortOrder
      );
      this.questions.forEach((q: any) => {
        q.type = questionTypes.find((qt) => qt.questionTypeId === q.questionTypeId);
      });
      this.questions = this.questions.filter((q: any) =>
        ['multiple_choice', 'text'].includes(q.type.ref)
      );
      this.title = res[1].evaluationTemplate.privateTitle;
      const answers = res[1].answers;
      const answerIds = answers.map((a: any) => a.answerOptionId);
      this.questions.forEach((q: any) => {
        q.answerOptions = q.answerOptions.sort((a, b) => a.sort - b.sort);
        q.answerOptions.forEach((opt: any) => {
          if (answerIds.includes(opt.answerOptionId)) {
            opt.selected = true;
          }
          if (opt.rawText === null) {
            opt.text = answers.find((a) => opt.answerOptionId === a.answerOptionId)?.text;
          }
        });
      });
      this.loading = false;
    });
  }
}
