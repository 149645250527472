<div class="header">
  <div class="sub-title">Results</div>
  <div class="title">{{ title }}</div>
</div>
<app-page-loading-spinner *ngIf="loading"></app-page-loading-spinner>
<div class="score-wrap" *ngIf="!loading">
  <div class="question" *ngFor="let question of questions; let i = index">
    <p class="question-title">{{i + 1}}. {{question.rawText}}</p>
    <div>
      <div
        class="answer-option"
        *ngFor="let answer of question.answerOptions"
        [class.left-padding]="question.answerOptions.length === 1"
      >
        <div
          *ngIf="!survey"
          [class.checkbox]="question.multipleAnswersAllowed"
          [class.radio]="!question.multipleAnswersAllowed"
          [ngClass]="answer.selected ? ((+answer.pointValue > 0.5) ? 'checked':
              (+question.pointValue === 0 || question.multipleAnswersAllowed ? 'nopoint':'incorrect')) :
              question.multipleAnswersAllowed && +answer.pointValue > 0.5 ? 'incorrect' : 'empty'"
        ></div>
        <div
          *ngIf="survey && answer.rawText"
          class="survey"
          [class.checkbox]="question.multipleAnswersAllowed"
          [class.radio]="!question.multipleAnswersAllowed"
          [ngClass]="answer.selected ? 'selected' :'empty'"
        ></div>
        <span>{{answer.rawText || answer.text}}</span>
      </div>
    </div>
  </div>
</div>
<div class="actions" *ngIf="!loading">
  <button class="primary-btn" [routerLink]="['/landing']">Back</button>
</div>
