<div class="question-wrap">
  <div class="question-front" (click)="questionHint(question)">
    <div class="question-text-box">
      <div class="question-title" [class.required]="question.required">
        <ul
          [class.no-style]="question.richText?.blocks[0]?.type === 'unstyled'"
          [class.number]="question.richText?.blocks[0]?.type === 'ordered-list-item'"
        >
          <li
            *ngFor="let block of question.richText.blocks"
            [style]="getStyles(block.inlineStyleRanges)"
          >
            {{ block.text }}
          </li>
        </ul>
      </div>
    </div>
    <div class="question-comment" *ngIf="question.rawHintText">
      <img src="/assets/img/help.png" />
    </div>
  </div>
  <div class="media-preview" *ngIf="question.assets.length">
    <img
      height="100px"
      [src]="getAttachmentUrl()"
      *ngIf="question.assets[0].assetMimeType.ext === 'jpg'"
    />
    <video
      height="100px"
      [src]="getAttachmentUrl()"
      controls
      *ngIf="question.assets[0].assetMimeType.ext === 'flv'"
    ></video>
  </div>

  <div class="question-select-wrap" *ngIf="question.answerOptions.length">
    <app-radio-button
      [question]="question"
      *ngIf="question.type.ref === 'multiple_choice' && !question.multipleAnswersAllowed"
    ></app-radio-button>
    <app-checkbox
      [question]="question"
      *ngIf="question.type.ref === 'multiple_choice' && question.multipleAnswersAllowed"
    ></app-checkbox>
    <app-free-text [question]="question" *ngIf="question.type.ref === 'text'"></app-free-text>
  </div>
</div>
<div class="border"></div>
