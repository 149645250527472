import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { CoursesService } from '../core/services/courses.service';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss'],
})
export class ScoreComponent implements OnInit {
  score: any = {};
  private courseId: any;
  private evaluationTemplateId: any;
  template: any;
  evaluationId: any;
  loading = false;
  questions = [];
  totalPoint: number;
  possiblePoint: number;

  constructor(
    public coursesService: CoursesService,
    public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.courseId = this.route.snapshot.params.courseId;
    this.evaluationTemplateId = this.route.snapshot.params.evaluationTemplateId;
    this.loading = true;
    this.coursesService.getScore(this.courseId).subscribe(
      (res: any) => {
        this.score = res;
        this.evaluationId = (
          this.score.evaluationContents.find(
            (c) => c.evaluationTemplateId === this.evaluationTemplateId
          ) || {}
        ).evaluationId;
        forkJoin([
          this.coursesService.getResults(this.evaluationId),
          this.coursesService.getQuestionTypes(),
        ]).subscribe(
          (r: any) => {
            const questionTypes = r[1];
            this.questions = r[0].evaluationTemplate.questions
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .filter((q) => q.answerOptions.length);
            // if (!this.survey) {
            //   this.questions = this.questions.filter(q => parseFloat(q.pointValue));
            // }
            const answers = r[0].answers;
            const answerIds = answers.map((a: any) => a.answerOptionId);
            this.questions.forEach((q: any) => {
              q.type = questionTypes.find((qt) => qt.questionTypeId === q.questionTypeId);
              q.answerOptions = q.answerOptions.sort((a, b) => a.sort - b.sort);
              q.answerOptions.forEach((opt: any) => {
                if (answerIds.includes(opt.answerOptionId)) {
                  opt.selected = true;
                }
                if (opt.rawText === null) {
                  opt.text = answers.find((a) => opt.answerOptionId === a.answerOptionId)?.text;
                }
              });
            });
            this.questions = this.questions.filter((q: any) =>
              ['multiple_choice', 'text'].includes(q.type.ref)
            );
            this.getPossiblePoint(this.questions);
            this.getTotalPoint(answers);
            // check for Survey
            if (this.totalPoint === 0 && this.possiblePoint === 0) {
              this.router.navigate(['/results', this.evaluationId, 'survey']);
            } else {
              this.loading = false;
            }
          },
          (error) => {
            this.loading = false;
          }
        );
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  getTotalPoint(answers) {
    this.totalPoint = answers.reduce((prev, curr) => {
      if (curr?.answerOption.pointValue) {
        const pointValue = parseFloat(curr.answerOption.pointValue);
        return pointValue > 1 ? prev + 1 : prev + pointValue;
      }
    }, 0);
  }

  getPossiblePoint(questions) {
    this.possiblePoint = questions.reduce((prev, curr) => {
      if (curr?.multipleAnswersAllowed) {
        return (
          prev +
          curr.answerOptions.reduce((pr, cu) => {
            const optPointValue = parseFloat(cu.pointValue);
            return optPointValue > 1 ? pr + 1 : pr + optPointValue;
          }, 0)
        );
      } else if (curr?.pointValue) {
        const pointValue = parseFloat(curr.pointValue);
        return pointValue > 1 ? prev + 1 : prev + pointValue;
      }
    }, 0);
  }

  percent() {
    return Math.round((this.totalPoint / this.possiblePoint) * 100) || 0;
  }

  templateName() {
    return (this.score.evaluationTemplateTitlesById || {})[this.evaluationTemplateId] || '';
  }
}
