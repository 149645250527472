import {
  getApiUrl,
  getConfigUrl,
  getClientNameFromHostname,
  getDatadogConfig,
  getHotjarConfig,
} from './utils';

export const environment = {
  production: true,
  configUrl: getConfigUrl(window.location),
  clientName: ENV.CLIENT_NAME || getClientNameFromHostname(window.location.hostname),
  apiUrl: ENV.API_URL || getApiUrl(window.location),
  ddRumConfig: getDatadogConfig(),
  hotjarConfig: getHotjarConfig(),
};
