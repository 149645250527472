import { Component } from '@angular/core';

@Component({
  selector: 'app-page-loading-spinner',
  templateUrl: './page-loading-spinner.component.html',
  styleUrls: ['./page-loading-spinner.component.scss'],
})
export class PageLoadingSpinnerComponent {
  constructor() {}
}
