import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  questionVal: any = {};
  @Input() get question() {
    return this.questionVal;
  }
  set question(val) {
    this.questionVal = val;
    this.questionChange.emit(this.questionVal);
  }
  @Output() questionChange = new EventEmitter();
  selected: any = [];

  constructor() {}

  toogleCheckbox(answer, e: any) {
    answer.checked = false;
    if (this.selected.includes(answer.answerOptionId)) {
      this.selected = this.selected.filter((s) => s !== answer.answerOptionId);
    } else if (this.selected.length < this.question.answerLimit || !this.question.answerLimit) {
      answer.checked = true;
      this.selected.push(answer.answerOptionId);
    }
    e.target.checked = answer.checked;

    this.question.answerValue = this.selected;
  }
}
