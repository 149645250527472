<app-page-loading-spinner *ngIf="loading"></app-page-loading-spinner>
<ng-container *ngIf="!loading">
  <div class="header">
    <div class="sub-title">Score</div>
    <div class="title">{{ templateName() }}</div>
  </div>
  <div class="score-wrap">
    <div class="your-score" *ngIf="evaluationId">
      <ng-container *ngIf="totalPoint === 0 && possiblePoint === 0; else score">
        <div>
          Score is not <br />
          available.
        </div>
      </ng-container>
      <ng-template #score>
        <div class="percents" *ngIf="evaluationId">{{ percent() }}%</div>
        <div>Score:</div>
        <div *ngIf="totalPoint !== null && possiblePoint !== null">
          {{totalPoint}}/{{ possiblePoint }}
        </div>
      </ng-template>
    </div>
    <div class="your-score" *ngIf="!evaluationId">
      <div>Score is not <br />yet available.</div>
    </div>
  </div>
  <div class="actions" [class.has-results]="evaluationId">
    <button class="primary-btn" [routerLink]="['/landing']">Back</button>
    <button *ngIf="evaluationId" class="primary-btn" [routerLink]="['/results', evaluationId]">
      Results
    </button>
  </div>
</ng-container>
